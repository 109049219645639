<template>
  <div :class="{ 'back-color': list.length === 0 }">
    <!-- 导航栏 -->
    <div class="nav-layout">
      <van-tabs
        v-model="active"
        @click="onClick"
        line-width="60"
        title-active-color="#ED301D"
        border
      >
        <template #nav-left>
          <div>
            <van-icon name="arrow-left" @click="$router.go(-1)" size="16" />
          </div>
        </template>
        <template #nav-right>
          <div>
            <van-icon name="ellipsis" size="16" />
          </div>
        </template>
        <van-tab title="我的评价" :name="1">
          <div class="blank-page" v-if="list.length <= 0">
            <div class="blank-img">
              <img src="~@/assets/common/no_evaluation_icon_is_available.png" />
            </div>
            <span>暂无评价</span>
          </div>
          <!-- 评论详情 -->
          <div class="content-layout" v-else>
            <div
              class="comments-card"
              v-for="(c_info, c_index) in list"
              :key="c_index"
            >
              <!-- 用户信息 -->
              <div class="user-info">
                <div class="user-img-box">
                  <img :src="c_info.head_img" alt="" />
                </div>
                <div class="name-and-time">
                  <div>{{ c_info.nickname }}</div>
                  <div class="comments-time">{{ c_info.created_date }}</div>
                </div>
              </div>
              <!-- 评价内容 -->
              <div class="comments-content">
                <p>
                  {{ c_info.content }}
                </p>
                <!-- 根据实际情况是否显示图片行 -->
                <div class="comments-img" v-if="c_info.goods_imgs.length > 0">
                  <div
                    class="comments-img-box"
                    v-for="(img, i_key) in c_info.goods_imgs"
                    :key="i_key"
                  >
                    <img
                      :src="img"
                      alt=""
                      @click="lookImg(c_info.goods_imgs, i_key)"
                    />
                  </div>
                </div>
                <div class="goods-card">
                  <div class="goods-img-box">
                    <img :src="c_info.goods_img" alt="" />
                  </div>
                  <div class="goods-title-price">
                    <div class="goods-title">
                      <span>{{ c_info.goods_name }}</span>
                      <span>￥{{ twoNum(c_info.total_money) }}</span>
                    </div>
                    <span class="specifications">{{ c_info.item_key }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab
          :title="'待评价' + (lengthNum > 0 ? '(' + lengthNum + ')' : '')"
          :name="2"
        >
          <AwaitEvaluate @monitor="monitor"></AwaitEvaluate>
          <!-- 待评价 -->
          <div class="blank-page" v-if="lengthNum <= 0">
            <div class="blank-img">
              <img src="~@/assets/common/no_evaluation_icon_is_available.png" />
            </div>
            <span>暂无评价</span>
          </div>
        </van-tab>
      </van-tabs>
      <!-- <div
        class="evaluate-text"
        :class="{ dynamic: active === 1 }"
        @click="switchoverBtn(1)"
      >
        <span>我的评价</span>
      </div> -->
      <!-- <div
        class="evaluate-text"
        :class="{ dynamic: active === 2 }"
        @click="switchoverBtn(2)"
      >
        <span>待评价</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import AwaitEvaluate from "./await-evaluate";
import { getMyRemainList } from "@/api/goods";
import { ImagePreview } from "vant";
export default {
  name: "my-evaluate",
  components: { AwaitEvaluate },
  data() {
    return {
      active:
        this.$route.query.active === undefined ? 1 : this.$route.query.active,
      lengthNum: "",
      list: [],
      page: 1,
      total: 0,
      isAjax: false,
      isEnd: false,
    };
  },
  mounted() {
    this.getMyRemainList();
  },
  methods: {
    lookImg(imgArr, index) {
      ImagePreview({
        images: imgArr,
        startPosition: index,
      });
    },
    goToDetail() {},
    onClick(num) {
      if (num === 1) {
        this.getMyRemainList();
      }
    },
    // switchoverBtn(num) {
    //   this.active = num;
    // },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getMyRemainList() {
      if (this.isAjax || this.isEnd) {
        return false;
      }
      this.isAjax = true;
      const res = await getMyRemainList();
      console.log(res);
      if (res.code * 1 === 1) {
        ++this.page;
        this.list = this.list.concat(res.data.items);
        this.total = res.data.total;
        if (this.list.length >= this.total) {
          this.isEnd = true;
        }
      }
      this.isAjax = false;
    },
    monitor(num) {
      this.lengthNum = num;
    },
  },
};
</script>

<style lang="less" scoped>
// 本页img图片样式
img {
  width: 100%;
  display: block;
}
.back-color {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
}
.nav-layout {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // padding: 12px;
  font-size: 18px;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
}
.evaluate-text {
  text-align: center;
}
.dynamic {
  color: #ed301d;
  font-weight: 600;
  border-bottom: 2px solid #ed301d;
}
.subscript {
  width: 64px;
  height: 2px;
  background-color: #ed301d;
}
// 评论详情
.comments-card {
  padding: 12px;
  background-color: #fff;
  .user-info {
    display: flex;
    .user-img-box {
      img {
        display: block;
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
    }
    .name-and-time {
      padding: 0 10px;
      font-size: 18px;
      .comments-time {
        font-size: 14px;
        color: #7d7d7d;
      }
    }
  }
  .comments-content {
    p {
      font-size: 16px;
    }
    .comments-img {
      display: flex;
      overflow: hidden;
      .comments-img-box {
        flex-shrink: 0;
        padding-right: 14px;
        width: 116px;
        img {
          width: 116px;
          height: 116px;
        }
      }
    }
  }
  .goods-card {
    display: flex;
    margin-top: 12px;
    background-color: #f8f8f8;
    border-radius: 16px;
    padding: 12px;
    .goods-img-box {
      width: 90px;
      img {
        display: block;
        width: 90px;
        height: 90px;
        border-radius: 6px;
      }
    }
    .goods-title-price {
      width: 100%;
      padding-left: 10px;
      .goods-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .specifications {
        color: #6f6f6f;
        background-color: #f5f5f5;
        font-size: 16px;
        padding: 4px 19px;
        border-radius: 6px;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 2; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
        word-break: break-all; /** 英语字母设定问题，它不会换行在代码中加入强制换行 可以解决这个问题**/
      }
    }
  }
}
/deep/.van-tabs__content {
  width: 100%;
  min-height: 94vh;
}
/deep/.van-tabs__nav {
  align-items: center;
}
/deep/.van-tabs__nav--line {
  padding: 0 12px 12px 12px;
  border-bottom: 1px solid rgb(238, 238, 238);
}
/deep/.van-tab__text {
  font-size: 18px;
}
.blank-page {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 16px;
  color: #afafaf;
  .blank-img {
    width: 246px;
  }
}
</style>
