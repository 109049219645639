<template>
  <div>
    <div class="content-card" v-for="(c_info, c_index) in list" :key="c_index">
      <div class="shop-title-left" @click="goToShop(c_info.shop_id)">
        <van-icon name="shop-o" />
        <span class="title-text">供应商：{{ c_info.shop_name }}</span>
        <van-icon name="arrow" />
      </div>
      <!-- 商品信息 -->
      <div class="goods-info">
        <div class="goods-images-box">
          <div class="buy-details" @click="goToDetail(c_info.goods_id)">
            <div class="goods-img-box">
              <img :src="c_info.goods_img" class="goods-img" />
            </div>
            <div class="sku-info">
              <div>{{ c_info.goods_name }}</div>
              <span
                class="specifications"
                v-for="(key, ind) in c_info.item_key"
                :key="ind"
              >
                {{ key }}
              </span>
            </div>
          </div>
        </div>
        <div class="comments-button">
          <!-- goPublishEvaluate -->
          <van-button
            type="danger"
            plain
            round
            class="button-appraise"
            @click="
              $router.push({
                name: 'publish-evaluate',
                query: { itemId: c_info.id },
              })
            "
          >
            写评价
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRemainList } from "@/api/goods";

export default {
  name: "await-evaluate",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      isAjax: false,
      isEnd: false,
    };
  },
  mounted() {
    this.getRemainList();
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ name: "goods", query: { goodsId: id, type: 1 } });
    },
    goToShop(id) {
      this.$router.push({ name: "shop-home", query: { shopId: id } });
    },
    switchoverBtn(num) {
      this.active = num;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getRemainList() {
      if (this.isAjax || this.isEnd) {
        return false;
      }
      this.isAjax = true;
      const res = await getRemainList();
      // console.log("-------待评价-----------------", res);
      if (res.code * 1 === 1) {
        ++this.page;
        this.list = this.list.concat(res.data.items);
        console.log(this.list);
        this.total = res.data.total;
        this.$emit("monitor", res.data.total);
        if (this.list.length >= this.total) {
          this.isEnd = true;
        }
      }
      this.isAjax = false;
    },
  },
};
</script>

<style lang="less" scoped>
// 订单卡片样式
.content-card {
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;
  margin: 12px;
  .shop-title-left {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #c6c6c6;

    .title-text {
      margin: 0 4px;
      font-weight: 500;
      font-size: 18px;
      color: #000;
    }
  }
}
// 商品信息
.goods-info {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid rgb(238, 238, 238);
  .goods-images-box {
    width: 100%;
    overflow-x: scroll;
  }
  .buy-details {
    flex-shrink: 0;
    display: flex;
    // justify-content: center;
    .goods-img-box {
      flex-shrink: 0;
      width: 90px;
      height: 90px;
      .goods-img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 6px;
      }
    }
    .sku-info {
      width: 170px;
      flex-shrink: 0;
      margin-left: 12px;
      font-size: 16px;

      div {
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .specifications {
        background-color: #f5f5f5;
        color: #6f6f6f;
        border-radius: 6px;
        font-size: 14px;
        padding: 4px 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .comments-button {
    flex-shrink: 0;
    .van-button {
      height: 40px;
      line-height: 40px;
    }
    .van-button--normal {
      padding: 0 20px;
    }
  }
}
.button-appraise {
  width: 93px;
  height: 39px;
  font-size: 16px;
}
</style>
